import { Container } from '@deltasierra/react/components/core';
import { Translations } from '@deltasierra/translations/react';

type LoginProps = {
    t: Translations<'Login'>;
};

export const Login = (_props: LoginProps): JSX.Element => (
    <Container
        sx={{
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
            marginY: '2rem',
            overflow: 'auto',
        }}
    >
        {'Login Page'}
    </Container>
);

Login.displayName = 'Login';
