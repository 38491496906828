'use client';

import { Login } from '@deltasierra/react/features/login';
import { useTranslations } from '@deltasierra/translations/react';

export default function LoginContainer(): JSX.Element {
    const t = useTranslations('Login');

    return <Login t={t} />;
}

LoginContainer.displayName = 'LoginContainer';
