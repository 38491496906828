'use client';

import { RedirectAppWebRouterError } from '@deltasierra/frontend/errors';
import { Suspense } from 'react';
import LoginContainer from '../../../components/LoginContainer';

export default function LoginPage(): JSX.Element {
    throw new RedirectAppWebRouterError('/login');

    return (
        <Suspense fallback="">
            <LoginContainer />
        </Suspense>
    );
}

LoginPage.displayName = 'LoginPage';
